


























import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import ContactsList from '@/components/ContactsList.vue';
import {Action} from 'vuex-class';
import {BusinessContact} from '@/domain/model/types';
import {applicationStore} from '@/store/modules/application';
import {profileStore} from '@/store/modules/profile';

@Component({
  name: 'dialog-add-default-backup-contact',
  components: {BaseDialog, ContactsList}
})
export default class DialogAddDefaultBackupContact extends Vue {
  @Prop() value!: boolean;
  @Prop() title!: string;
  @Prop() subtitle!: string;
  @Prop() defaultContact?: any | null;
  @Prop({default: false}) multi?: boolean;
  @Prop({default: '+ Add Selected'}) buttonText?: string;

  loading: boolean = false;
  selectedContact: any = null;

  @Action addContactToDefaultBackupList;

  get businessId() {
    return applicationStore.businessId;
  }

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get isDisabled() {
    return this.selectedContact === null || this.selectedContact === undefined
  }

  get filterID() {
    if (this.defaultContact) {
      return this.defaultContact.id || this.defaultContact.uid;
    }
    return null;
  }

  onContactSelected(contacts: BusinessContact[]) {
    if (this.multi) {
      this.selectedContact = contacts
    } else {
      this.selectedContact = contacts[0]
    }
  }

  async onAddDefaultBackupContact() {
    if (!this.selectedContact) {
      console.log('No contact selected');
      return;
    }
    this.$emit('default-contact-selected', this.selectedContact);
    this.show = false;
  }
}
